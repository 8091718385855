/**
 * Landing Page跟商品ID對應表(一個Landing Page需要對應到多項商品) - 用來重新導向到Landing Page，還有撈出Landing Page的商品資料。
 */
export default new Map(
  Object.entries({
    'farcent-dehumidifier': ['1414', '1186', '1657', '1413'],

    'crocoil-roach-trap': ['1205', '1206', '1207'],
    // 'roolen-theta': ['1306'],
    // 'roolen-theta': ['1306', '2431'],
    theta: ['1306', '2431', '3017'],

    'unipapa-pure': ['2079', '2113', '3019'],

    'mosquito-away': [
      '2119',
      '2120',
      '2121',
      '2122',
      '2123',
      '2338',
      '2337',
      '2130',
      '2131',
      '2132',
      // 防蚊完全對策組
      '2483',
      // 2022-1111
      '2236',
      '2233',
      '2333',
      '2336',
    ],

    'room-slippers': [
      '2148',
      '2149',
      '2150',
      '2151',
      '2246',
      // 鞋墊 - 棉布室內拖鞋墊 (2對) 2314
      '2316',
      '2317',
      '2318',
      '2319',
      // 鞋墊 - 竹蓆室內拖鞋墊 (2對) 2313
      '2315',
      '2320',
      '2321',
      '2322',
    ],

    surco: ['2141', '2328'],

    spray: ['2142', '2143', '2145', '2329', '2330', '3191', '3192', '3193'],

    // removed
    // frangrance: ['2193', '2209', '2210', '2335', '2336', '2211', '2212'],

    'mouth-wash': ['2323', '2327', '2340', '2425'],

    milkshabushabu: ['2344', '2346', '2347'],

    sponge: ['2361'],

    'extension-cord': [
      // 1.8m
      '2367',
      // 2.8m
      '2368',
      // 超早鳥價
      '2372',
      '2373',
      // 早鳥預購價
      '2374',
      '2375',
      // 揪團 6 套組
      '2376',
      '2377',
      // 壁貼背扣組
      '2371',
      // 整線扣
      // '2370',
      // 三轉二轉接頭
      '2369',
      // 2.8m 有序延長線 （黑*3 白*3） (6 套組)
      '2740',
      // 1.8m 有序延長線 （黑*3 白*3） (6 套組)
      '2739',
    ],

    'extension-cord/black': [
      // black---------------------------------------------------------------

      // 1.8m 黑色有序延長線
      '2433',
      // 2.8m 黑色有序延長線
      '2434',
      // 1.8m 黑色有序延長線（6套組）
      '2435',
      // 2.8m 黑色有序延長線（6套組）
      '2436',

      // 黑色壁貼背扣組
      '2438',
      // 黑色三轉二轉接頭
      '2437',
    ],

    'bath-sponge': ['2400'],
    // 'bath-sponge': ['2400', '2625'],

    // removed
    // 'lips-cup': ['2439', '2440'],

    'pocket-paper': ['2441', '2442', '2443'],

    'tissue-holder': ['2450'],

    'line-buckle': ['2370', '2399'],

    // 廚房重油清潔
    // 'oil-clean': ['2461', '2460', '2469', '2470'],
    'grease-washing': ['2461', '2460', '2469', '2470'],

    // 強淨碗盤清潔
    // 潔淨慕斯噴頭、清潔綜合組點進來的預設頁面放碗盤清潔的頁面
    // 'oil-clean2': ['2459', '2458', '2467', '2468', '2465', '2466'],
    dishwashing: ['2459', '2458', '2467', '2468', '2465', '2466'],

    // 水垢透淨清潔
    // 'oil-clean3': ['2463', '2462', '2471', '2472'],
    'scale-remover': ['2463', '2462', '2471', '2472'],

    // 深層去霉凝膠
    // 'oil-clean4': ['2464', '2473', '2474'],
    'mildew-cleaner': ['2464', '2473', '2474'],

    // removed
    // repellent: ['2510', '2524', '2525', '2554', '2555'],

    // 掛鉤組
    'wall-hook': ['2532'],
    // C字扣組
    'cable-hook': ['2533'],
    // 整線扣組
    // 'cable-organizer': ['2531'],
    'cable-organizer': ['2531', '2628'],
    // 天絲床包
    'bed-linen': [
      '2560',
      '2558',
      '2556',
      '2559',
      '2561',
      '2562',
      '2563',
      '2564',
      '2565',
      '2639',
      '2646',
    ],

    // 'dehumidifier-box': ['2630', '2631', '2632'],
    loop: ['2630', '2631', '2632', '2741', '2839', '2835', '2630'],

    // removed
    // 'ggxup-extension-cord': ['2638'],

    'light-the-night': ['2662', '2689'],

    'summer-beverage-cassette': ['2722', '2721', '2720', '2708'],

    uphanger: ['2759', '2757', '2758', '2760', '2761'],

    'extension-cord65W': [
      // 2.8m 黑色有序延長線 快充版
      '2871',
      // 2.8m 白色有序延長線 快充版
      '2870',

      // 1.8m 黑色有序延長線 快充版
      // '2869',
      '2899',

      // 1.8m 白色有序延長線 快充版
      // '2868',
      '2898',

      '2869',
      '2868',

      '3154',
      '3155',
      '3194',
      '3195',

      '3194',
      '3195',
    ],

    'extension-cord-modular': [
      3628, //【早鳥價】1.8m 白色有序延長線 模組快充版
      3629, //   【早鳥價】1.8m 黑色有序延長線 模組快充版

      3630, //【早鳥價】有序快充方塊 白
      3631, //        【早鳥價】有序快充方塊 黑

      3605,
      3606,

      3618,
      3617,

      3699,
      3698,

      3701,
      3700,
    ],

    'flip-mirror': ['3178'],

    '67w-charger': ['3783', '3782', '3806', '3805', '3796', '3795', '3794'],
  })
);
